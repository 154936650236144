<template>
  <headerPc v-if="hasPc" :gData="gData" ></headerPc>
  <headerMobile v-else :gData="gData"></headerMobile>
</template>

<script>
import Config from 'utils/Config';
export default {
  name: 'xmHeader',
  props: {
    hasPc: Boolean,
    index : Number
  },
  data(){
    return {
      gData : {
        index : this.index,
        // logo :"//img.ibestfanli.com/xmilesWebsite2/img_logo_svg.svg",
        tabIcon : "//img.ibestfanli.com/xmOfficial/tabIcon.png",
        tabClose : "//img.ibestfanli.com/xmOfficial/tabClose.png",
        developerLogin :"http://it.yingzhongshare.com/index-frontend/index.html#/login",
        btns : [
          {name:"首页",url: `${Config.domain}/index.html`},
          {name:"关于我们",url: `${Config.domain}/about.html`},
          {name:"小迈动态",url: `${Config.domain}/dynamic.html`},
          {name:"员工发展",url: `${Config.domain}/develop.html`},
          // {name:"加入我们",url: `${Config.domain}/hire.html`}
          {
            name:"加入我们",
            children: [{
              name: "社会招聘", url: 'https://app.mokahr.com/social-recruitment/xmiles/39484'
            },{
              name: "校园招聘", url: 'https://app.mokahr.com/campus-recruitment/xmiles/37612'
            }]
          }
        ]
      }
    }
  },
  components: {
    headerMobile: (resolve) => {
      require(["./platform/headerMobile.vue"], resolve);
    },
    headerPc: (resolve) => {
      require(["./platform/headerPc.vue"], resolve);
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
